import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react';
import Spinner from '../../assets/images/spinner.png';
import { FC, useEffect, useState, useRef } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import './voice-chat.styles.css';
import { useQueryClient } from '@tanstack/react-query';
import VoiceWaveForm from './VoiceWaveForm';
import { sendAudioBuffer } from '../../clients/be';
import { InputMic } from './inputMic';
import { useSpeaking } from '../../stores/speaking';

const VoiceChatWindow: FC<{
  onClose: () => void;
  conversationId: string;
}> = ({ conversationId, onClose }) => {
  const queryClient = useQueryClient();

  const { isSpeaking } = useSpeaking();

  const [sourceUrl, setSourceUrl] = useState<string>('');

  const [isConnecting, setIsConnecting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const audioResponseRef = useRef<HTMLAudioElement | null>(null);

  async function sendAudio(audioBlob: Blob) {
    setIsProcessing(true);
    try {
      const audioUrl = await sendAudioBuffer(conversationId, audioBlob);
      setSourceUrl(audioUrl);
      queryClient.invalidateQueries({
        queryKey: ['conversation', conversationId],
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  }

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    setIsConnecting(true);
    timeoutId = setTimeout(() => {
      setIsConnecting(false);
    }, 1500);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (!sourceUrl) return;

    if (!audioResponseRef.current) return;

    audioResponseRef.current.src = sourceUrl;
    audioResponseRef.current.load();
  }, [sourceUrl]);

  useEffect(() => {
    if (!audioResponseRef.current) return;

    audioResponseRef.current.addEventListener('ended', () => {
      setIsAudioPlaying(false);
    });

    audioResponseRef.current.addEventListener('canplaythrough', () => {
      if (!audioResponseRef.current) return;
      audioResponseRef.current.play();
    });

    audioResponseRef.current.addEventListener('play', () => {
      setIsAudioPlaying(true);
    });
  }, [audioResponseRef.current]);

  const onCloseVoiceChat = () => {
    if (audioResponseRef.current) {
      audioResponseRef.current.pause();
    }
    onClose();
  };

  return (
    <Flex
      p={5}
      w="full"
      bg="black"
      zIndex={5}
      pos="relative"
      flexDir="column"
      h={{ base: 'calc(100vh - 1.5rem)', md: 'full' }}
      alignItems="space-between"
      borderRadius={{ base: '0', md: '40px' }}
    >
      <audio ref={audioResponseRef} />

      <Flex
        w="full"
        h="3rem"
        alignItems="center"
        justifyContent="flex-end"
        pe={3}
      >
        <CloseIcon
          cursor="pointer"
          color="white"
          boxSize={3}
          onClick={onCloseVoiceChat}
        />
      </Flex>

      <Flex
        gap={5}
        w="full"
        h="full"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {isConnecting && (
          <>
            <Image src={Spinner} alt="spinner" className="spinner" />
            <Text textAlign="center" color="white">
              Connecting
            </Text>
          </>
        )}
        {isSpeaking && (
          <Box
            bg="white"
            width="14rem"
            height="14rem"
            borderRadius="full"
            animation={'grow 3s linear infinite'}
          />
        )}
        {isAudioPlaying && !isSpeaking && <VoiceWaveForm boxSize={1} />}
        {isProcessing && (
          <Flex
            width="120px"
            justifyContent="space-between"
            alignItems="center"
          >
            {Array.from({ length: 3 }).map((_, index) => (
              <Box
                key={index}
                bg="white"
                width="1.2rem"
                height="1.2rem"
                borderRadius="full"
                className={`pulse-bubble-${index + 1} animate-pulse`}
              />
            ))}
          </Flex>
        )}
        {!isAudioPlaying && !isConnecting && !isProcessing && (
          <InputMic onDataAvailable={sendAudio} />
        )}
      </Flex>
    </Flex>
  );
};

export default VoiceChatWindow;
