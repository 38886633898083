import axios from 'axios';
import { Message } from '../models/chat';

const baseURL =
  process.env.REACT_APP_API_URL ||
  'https://wh-dev-voice-assistant-na-svc.azurewebsites.net';

const api = axios.create({
  baseURL,
});

export const fetchConversation = async (
  conversationId: string
): Promise<Message[]> => {
  const { data } = await api.get<Message[]>(`/api/convo/history`, {
    params: {
      conversationId,
    },
  });
  return data;
};

export const sendMessage = async (
  conversationId: string,
  textMessage: string
): Promise<void> => {
  try {
    await api.post('/api/convo/text', {
      conversationId,
      textMessage,
    });
  } catch (error) {
    console.error('Error sending message:', error);
  }
};

export const sendAudioBuffer = async (
  conversationId: string,
  audioBlob: Blob
): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.wav');

    const response = await fetch(
      `${baseURL}/api/convo/voice?ConversationId=${conversationId}&Voice=1`,
      {
        method: 'POST',
        body: formData,
      }
    );

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    return url;
  } catch (error) {
    console.error('Error sending audio:', error);
    throw new Error('Error while sending audio');
  }
};
