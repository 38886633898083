import { MicIcon } from 'lucide-react';
import { FC, useEffect, useRef } from 'react';
import useAudioRecorder from '../../hooks/audio';
import { useSpeaking } from '../../stores/speaking';
import VoiceWaveForm from './VoiceWaveForm';

export const InputMic: FC<{
  onDataAvailable: (data: Blob) => void;
}> = ({ onDataAvailable }) => {
  const { isListening, continueListening, startListening } =
    useAudioRecorder(onDataAvailable);
  const { setIsSpeaking } = useSpeaking();

  const isInitialized = useRef(false);

  useEffect(() => {
    console.log('isListening', isListening);
    setIsSpeaking(isListening);
  }, [isListening, setIsSpeaking]);

  const handleStartListening = () => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      startListening();
    } else {
      continueListening();
    }
  };

  if (isListening) return <VoiceWaveForm boxSize={0.4} />;

  return (
    <div className="w-full h-fit flex flex-col items-center justify-center">
      <button
        onClick={handleStartListening}
        className="w-fit h-fit hover:scale-105"
      >
        <MicIcon className="text-white" size={94} />
      </button>
      <p className="text-white text-sm mt-5">Tap on the mic to speak.</p>
    </div>
  );
};
