import './App.css';
import {
  Box,
  Center,
  ChakraProvider,
  Image,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import ChatUI from './components/chat/chat';
import VoiceChatWindow from './components/voice-chat/VoiceChatWindow';
import { useState, useEffect } from 'react';

function App() {
  const isIOS = useMediaQuery('(pointer: fine)');
  const {
    isOpen: isVoiceChatOpen,
    onClose: onVoiceChatClose,
    onOpen: onVoiceChatOpen,
  } = useDisclosure();

  const [conversationId, setConversationId] = useState<string | null>(null);

  const generateGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  useEffect(() => {
    if (!conversationId) {
      setConversationId(generateGUID());
    }
  }, [conversationId]);

  return (
    <ChakraProvider>
      <Center h={{ base: isIOS ? '82vh' : '88vh', md: '95vh' }}>
        <Box
          pos="relative"
          w={{ base: '100%', md: 'auto' }}
          h={{ base: isIOS ? '95vh' : '', md: '667px' }}
        >
          <Image
            src="./iphone.png"
            w="auto"
            h="full"
            display={{ base: 'none', md: 'block' }}
          />
          <Box
            pos={{ base: 'initial', md: 'absolute' }}
            top="60px"
            left="15px"
            w={{ base: 'full', md: '295px' }}
            h={{ base: 'full', md: '585px' }}
            overflow="hidden"
          >
            {conversationId && (
              <ChatUI
                conversationId={conversationId}
                openVoiceChat={onVoiceChatOpen}
              />
            )}
          </Box>
          {isVoiceChatOpen && (
            <Box h="full" w="full" pos="absolute" top={{ base: '3rem', md: 0 }}>
              {conversationId && (
                <VoiceChatWindow
                  conversationId={conversationId}
                  onClose={onVoiceChatClose}
                />
              )}
            </Box>
          )}
        </Box>
      </Center>
      <Box h="5vh" display="flex" alignItems="center" pos="relative" zIndex={0}>
        <Box display="flex" pos="absolute" w="full" justifyContent="center">
          <Text fontSize="xs" me="0.4rem">
            Powered by
          </Text>
          <Center>
            <Image
              w="100px"
              src="https://udcbep1devassests.azureedge.net/icons/techLogo.svg"
            />
          </Center>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
