import { Box, HStack } from '@chakra-ui/react';

const VoiceWaveForm = ({ boxSize }: { boxSize: number }) => {
  return (
    <HStack h="fit-content" justifyContent="center">
      {Array.from({ length: 5 }).map((_, index) => (
        <Box
          px={`${boxSize}rem`}
          py={`${boxSize * 2}rem`}
          key={index}
          borderRadius="full"
          background="white"
          className={`box box${index + 1}`}
        />
      ))}
    </HStack>
  );
};

export default VoiceWaveForm;
