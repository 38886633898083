import { useRef, useState } from 'react';

const useAudioRecorder = (sendAudio: (audioBlob: Blob) => void) => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [isListening, setIsListening] = useState(false);

  const startListening = async () => {
    if (isListening) return;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const audioContext = new (window.AudioContext || window.AudioContext)();
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      source.connect(analyser);
      analyser.fftSize = 2048;
      const bufferLength = analyser.fftSize;
      const dataArray = new Uint8Array(bufferLength);

      const threshold = 5; // Adjust this value to set the pause detection threshold
      const pauseDuration = 2000; // Adjust this value to set the pause duration (in ms)
      let silenceStart: number | null = null;

      const checkSilence = () => {
        analyser.getByteTimeDomainData(dataArray);
        const isSilent = dataArray.every(
          (val) => Math.abs(val - 128) < threshold
        );

        if (isSilent) {
          if (silenceStart === null) {
            silenceStart = performance.now();
          } else if (performance.now() - silenceStart > pauseDuration) {
            stopListening();
            return;
          }
        } else {
          silenceStart = null;
        }

        requestAnimationFrame(checkSilence);
      };

      mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
        audioChunksRef.current.push(event.data);
      });

      mediaRecorderRef.current.addEventListener('stop', () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: 'audio/mp3',
        });

        sendAudio(audioBlob);
        audioChunksRef.current = [];
        audioContext.close();
        // mediaRecorderRef.current = null;
        setIsListening(false);
      });

      mediaRecorderRef.current.start();
      setIsListening(true);
      checkSilence();
    } catch (error) {
      alert('Error accessing microphone. Please check your audio permissions.');
      console.error('Error starting audio recording:', error);
    }
  };

  const continueListening = () => {
    if (isListening) return;
    mediaRecorderRef.current?.start();
    setIsListening(true);
  };

  const stopListening = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== 'inactive'
    ) {
      mediaRecorderRef.current.stop();
      setIsListening(false);
    }
  };

  return { startListening, continueListening, stopListening, isListening };
};

export default useAudioRecorder;
