import { create } from 'zustand';

type SpeakingState = {
	isSpeaking: boolean;
	setIsSpeaking: (isSpeaking: boolean) => void;
};

export const useSpeaking = create<SpeakingState>((set) => ({
	isSpeaking: false,
	setIsSpeaking: (isSpeaking) => set({ isSpeaking }),
}));
